import { ThemeProvider, createTheme, useMediaQuery } from '@mui/material';
import './App.css';
import { ChatBotComponent } from './components';
import { Route, Routes, useSearchParams } from 'react-router-dom';
import ChatBotUi from './components/chatbotui';
import { useEffect, useMemo, useState } from 'react';

function App() {
  const [searchParams] = useSearchParams();
  const [allParams, setAllParams] = useState({});
  useEffect(() => {
    for (let [key, value] of searchParams.entries()) {
      setAllParams((params) => {
        const newParams = { ...params };
        newParams[key] = value;
        return newParams;
      });
    }
  }, []);
  const theme = useMemo(
    () =>
      createTheme({
        palette: {
          mode: 'light',
          primary: {
            main:
              (allParams?.primaryColor
                ? '#' + allParams.primaryColor
                : false) ||
              '#384383',
          },
        },
      }),
    [allParams]
  );
  const getTarget = (url) => {
      return url ? new URL(url).origin : "*"
  }
  return (
    <ThemeProvider theme={theme}>
      <Routes>
        <Route path="/" element={<ChatBotNewUi params={allParams} getTarget={getTarget} />} />
        <Route path="/bot" element={<ChatBotComponent params={allParams} getTarget={getTarget} />} />
        <Route path="/*" element={<ChatBotComponent params={allParams} />} />
      </Routes>
    </ThemeProvider>
  );
}

export default App;

const ChatBotNewUi = ({ params = {}, getTarget }) => {
  const [chatData, setChatData] = useState([]);
  const isMobile = useMediaQuery('(max-width:425px)');

  useEffect(() => {
    const root = document.getElementById('root');
    if (root) {
      root.style.bottom = 0;
      root.style.right = 0;
      root.style.width = '100%';
      root.style.height = '100%';
      if (isMobile) {
        root.style.padding = 0;
      } else {
        root.style.padding = '50px';
      }
    }
    const search =
      typeof window !== 'undefined' ? window.location.search.substring(1) : '';
    if (search) {
      window.top?.postMessage({ isFullWidth: true }, getTarget(params?.url));
    }
    return () => {
      if (root) {
        root.style.bottom = '39px';
        root.style.right = '34px';
        root.style.width = 'unset';
        root.style.height = 'unset';
        root.style.padding = 0;
      }
    };
  }, [isMobile]);
  
  useEffect(() => {
    window.parent.postMessage(
      {
        tracking: "vitabot",
        event: "dedicated_chat_opened"
      },
      "*"
    )
  }, [])

  return (
    <ChatBotUi
      handleClick={() => {}}
      containerRef={null}
      setTotalUnreadMessage={() => {}}
      chatData={chatData}
      setChatData={setChatData}
      isFullscreen={true}
      params={params}
      getTarget={getTarget}
    />
  );
};
